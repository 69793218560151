import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { AppsAction } from "../Actions/AppAction"


import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import PrivateRoute from "../../../Helpers/privateRoute"
import Loading from '../../../Helpers/Loading'


const Home = React.lazy(() => import('../../Home/Component/Home'))
const Achievements = React.lazy(() => import('../../Pages/Component/Achievements'))
const FaqPage = React.lazy(() => import('../../Pages/Component/FaqPage'))
const Howitwork = React.lazy(() => import('../../Pages/Component/Howitwork'))
const Agreement = React.lazy(() => import('../../Pages/Component/Agreement'))
const Termsofuse = React.lazy(() => import('../../Pages/Component/Termsofuse'))

const Login = React.lazy(() => import('../../Login/Component/Login'))
const Signup = React.lazy(() => import('../../Signup/Component/Signup'))
const ActiveAccount = React.lazy(() => import('../../Signup/Component/ActiveAccount'))
const ForgetPassword = React.lazy(() => import('../../Signup/Component/ForgetPassword'))

const ResetPassword = React.lazy(() => import('../../Signup/Component/ResetPassword'))

const Dashboard = React.lazy(() => import('../../Dashboard/Component/Dashboard'))


const AddProduct = React.lazy(() => import('../../AddProduct/Component/AddProduct'))
const ProductList = React.lazy(() => import('../../ListProduct/Component/ProductList'))
const ProductTour = React.lazy(() => import('../../ProductTour/Component/ProductTour'))
const ProductTransfer = React.lazy(() => import('../../ProductTransfer/Component/ProductTransfer'))

const Profile = React.lazy(() => import('../../Profile/Component/Profile'))
const Contact = React.lazy(() => import('../../Profile/Component/Contact'))
const Payment = React.lazy(() => import('../../Profile/Component/Payment'))
const Documents = React.lazy(() => import('../../Profile/Component/Documents'))
const ProductContentEdit = React.lazy(() => import('../../ProductContentEdit/Component/ProductContentEdit'))
const ProductSpecialOffers = React.lazy(() => import('../../ProductSpecialOffers/Component/ProductSpecialOffers'))
const ProductPriceEdit = React.lazy(() => import('../../ProductPriceEdit/Component/ProductPriceEdit'))

const ProductReservations = React.lazy(() => import('../../ProductReservations/Component/ProductReservations'))
const ReservationDetail = React.lazy(() => import('../../ProductReservations/Component/ReservationDetail'))


const ProductOperation = React.lazy(() => import('../../ProductOperation/Component/ProductOperation'))

const ProductAvailability = React.lazy(() => import('../../ProductAvailability/Component/ProductAvailability'))
const ListProductTransfer = React.lazy(() => import('../../ListProductTransfer/Component/ListProductTransfer'))
const Help = React.lazy(() => import('../../Pages/Component/Help'));


const EditorList = React.lazy(() => import('../../Editor/Component/EditorList'))


const PriceContentEdit = React.lazy(() => import('../../ProductAvailability/Component/PriceContentEdit'))


class App extends Component {


    componentDidMount() {

        if (localStorage.getItem("guid")) {
            this.props.AppsAction(localStorage.getItem("guid"));
        } else {
            this.props.AppsAction();
        }
    }


    render() {

        const { loading } = this.props.appData;

        return (
            <>{loading ? "" :
                <Suspense fallback={<Loading />}>
                    <Router>

                        <div>
                            <Switch>
                                <Route path="/" exact strict component={Home} />
                                <Route path="/Achievements" exact strict component={Achievements} />
                                <Route path="/FaqPage" exact strict component={FaqPage} />
                                <Route path="/Howitwork" exact strict component={Howitwork} />
                                <Route path="/Agreement" exact strict component={Agreement} />
                                <Route path="/Terms" exact strict component={Termsofuse} />
                                <Route path="/Login" exact strict component={Login} />
                                <Route path="/Help" exact strict component={Help} />
                                <Route path="/Signup" exact strict component={Signup} />
                                <Route path="/ActiveAccount/:code" exact strict component={ActiveAccount} />
                                <Route path="/ForgetPassword" exact strict component={ForgetPassword} />
                                <Route path="/ResetPassword/:code" exact strict component={ResetPassword} />

                                <PrivateRoute path="/Dashboard" component={Dashboard} />
                                <PrivateRoute path="/AddProduct" component={AddProduct} />
                                <PrivateRoute path="/ProductList" component={ProductList} />
                                <PrivateRoute path="/ListProductTransfer" component={ListProductTransfer} />
                                <PrivateRoute path="/ProductTour/:id" component={ProductTour} />
                                <PrivateRoute path="/ProductContentEdit/:id" component={ProductContentEdit} />
                                <PrivateRoute path="/ProductSpecialOffers/:id" component={ProductSpecialOffers} />
                                <PrivateRoute path="/ProductPriceEdit/:id" component={ProductPriceEdit} />
                                <PrivateRoute path="/ProductReservations" component={ProductReservations} />
                                <PrivateRoute path="/ProductOperation" component={ProductOperation} />
                                
                                <PrivateRoute path="/ReservationDetail/:id" component={ReservationDetail} />
                                <PrivateRoute path="/Profile" component={Profile} />
                                <PrivateRoute path="/Contact" component={Contact} />
                                <PrivateRoute path="/Payment" component={Payment} />
                                <PrivateRoute path="/Documents" component={Documents} />
                                <PrivateRoute path="/EditorList" component={EditorList} />

                                <PrivateRoute path="/PriceContentEdit/:id" component={PriceContentEdit} />


                                <PrivateRoute path="/ProductTransfer/:id" component={ProductTransfer} />
                                <PrivateRoute path="/ProductAvailability" component={ProductAvailability} />
                            </Switch>

                        </div>

                    </Router>
                </Suspense>
            }
            </>
        )
    }
}

const mapStateToProps = (state) => ({

    appData: state.appData,
    loginData: state.loginData
});



export default connect(mapStateToProps, { AppsAction })(App)
