import React, { Component } from 'react'
import "../../../Assets/Css/newDashboard.css"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import "bootstrap/scss/bootstrap.scss";
import { Menu } from 'antd';
import LanguageSet from '../../../Helpers/LanguageSet';

class SupplierMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: 'light',
            current: '1',
        }

    }

    changeTheme = value => {
        this.setState({
            theme: value ? 'dark' : 'light',
        });
    };

    handleClick = e => {

        this.setState({
            current: e.key,
        });
    };


    render() {


        const { loginData, loadingLogin, isAuth } = this.props.loginData
        const { SubMenu } = Menu;

        return (
            <>
                <div className='menu'>

                    {!loadingLogin && isAuth ?
                        <div className='companyInfo'>
                            <div className='avatar'>
                                <img src="/Assets/img/tourzoneIcons.svg" alt="icon" />
                            </div>
                            <div className='infos'>
                                <div className='companyName'>
                                    {loginData.info.companyName}
                                </div>
                                <div className='nameSurname'>
                                    {loginData.info.officalName} {loginData.info.officalSurname}
                                </div>

                                {
                                    loginData.info.approved === 1 ?
                                        <span className="badge bg-primary"><LanguageSet code="LLM_APPROVED" /></span>
                                        :
                                        <span className="badge bg-warning"><LanguageSet code="LLM_WAITING" /></span>
                                }

                            </div>
                        </div>
                        : ""}
                    <Menu
                        theme={this.state.theme}
                        onClick={this.handleClick}
                        style={{ width: 250 }}
                        defaultOpenKeys={['sub1']}
                        selectedKeys={[this.state.current]}
                        mode="inline"
                    >
                        <Menu.Item key="1" icon={<i className="glyph-icon iconsminds-shop"></i>}>
                            <Link to="/Dashboard"><LanguageSet code="LLM_DASHBOARD" /></Link>
                        </Menu.Item>
                        <SubMenu key="sub2" icon={<i className="glyph-icon simple-icon-layers"></i>} title={<LanguageSet code="LLM_INVENTORY" />}>
                            <Menu.Item key="2">
                                <Link to="/ProductList"><LanguageSet code="LLM_PRODUCT" /></Link>
                            </Menu.Item>
                            <Menu.Item key="2-1">
                                <Link to="/ListProductTransfer"><LanguageSet code="LLM_TRANSFER_PRODUCT" /></Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub4" icon={<i className="glyph-icon iconsminds-pantone"></i>} title={<LanguageSet code="LLM_BOOKINGS" />}>
                            <Menu.Item key="3">
                                <Link to="/ProductReservations"><LanguageSet code="LLM_RESERVATIONS" /></Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub5" icon={<i className="glyph-icon iconsminds-calendar-4"></i>} title={<LanguageSet code="LLM_AVAILABILITY" />}>
                            <Menu.Item key="4">
                                <Link to="/ProductAvailability"><LanguageSet code="LLM_PRODUCT_AVAILABILITY" /></Link>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu key="sub6" icon={<i className="glyph-icon iconsminds-shuffle-2"></i>} title={<LanguageSet code="LLM_PRODUCT_OPERATIONS" />}>
                            <Menu.Item key="91">
                                <Link to="/ProductOperation"><LanguageSet code="LLM_PRODUCT_OPERATIONS" /></Link>
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu key="sub7" icon={<i className="glyph-icon iconsminds-coins"></i>} title={<LanguageSet code="LLM_FINANCE" />}>
                        </SubMenu>
                        <SubMenu key="sub8" icon={<i className="glyph-icon iconsminds-line-chart-1"></i>} title={<LanguageSet code="LLM_REPORT" />}>
                        </SubMenu>
                        <SubMenu key="sub9" icon={<i className="glyph-icon simple-icon-settings"></i>} title={<LanguageSet code="LLM_SETTINGS" />}>
                            <Menu.Item key="91">
                                <Link to="/Profile"><LanguageSet code="LLM_MENU_COMPANY" /></Link>
                            </Menu.Item>
                            <Menu.Item key="92">
                                <Link to="/Contact"><LanguageSet code="LLM_MENU_CONTACT" /></Link>
                            </Menu.Item>
                            <Menu.Item key="93">
                                <Link to="/Payment"><LanguageSet code="LLM_MENU_PAYMENT" /></Link>
                            </Menu.Item>
                            <Menu.Item key="94">
                                <Link to="/Documents"><LanguageSet code="LLM_MENU_DOCUMENT" /></Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub10" icon={<i className="glyph-icon simple-icon-question"></i>} title={<LanguageSet code="LLM_HELP" />}>
                            <Menu.Item key="101">
                                <Link to="/Achievements"><LanguageSet code="LLM_HELP_AGREEMENT" /></Link>
                            </Menu.Item>
                            <Menu.Item key="102">
                                <Link to="/Howitwork"><LanguageSet code="LLM_HELP_HOW_DOES_IT_WORK" /></Link>
                            </Menu.Item>
                            <Menu.Item key="103">
                                <Link to="/Terms"><LanguageSet code="LLM_HELP_TERMS_OF_USE" /></Link>
                            </Menu.Item>
                            <Menu.Item key="104">
                                <Link to="/Help"><LanguageSet code="LLM_HELP_HELP_CENTER" /></Link>
                            </Menu.Item>
                        </SubMenu>


                        <Menu.Item key="X101" icon={<i className="glyph-icon iconsminds-shop"></i>}>
                            <Link to="/EditorList"><LanguageSet code="LLM_MAIL_EDITOR" /></Link>
                        </Menu.Item>
                    </Menu>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    loginData: state.loginData,
    languageData: state.languageData
})



export default connect(mapStateToProps)(SupplierMenu)